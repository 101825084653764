<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center card-header-radius"
        :class="!state.formData.status ? 'border-bottom' : ''">
        <h6 class="fs-16 mb-0">{{ $t('alert.saunaRoomSetting') }}</h6>
        <div class="d-flex" style="column-gap: 10px; height: 38px">
          <label for="showDate" class="label mb-0 d-flex align-items-center cursor-pointer">
            <div class="form-check form-switch p-0">
              <input :disabled="disabled" v-model="state.formData.status" class="form-check-input checkbox ml-2 p-0"
                @change="handleChangeInput" type="checkbox" role="switch" id="showDate">
            </div>
          </label>
          <b-button v-if="state.formData.status" :disabled="currentFacilityId === '' || disabled || state.isLoading" variant="primary"
            class="waves-effect waves-light w-100 d-flex justify-content-center" @click="handleSubmit">
            <Loading v-if="state.isLoading" />
            更新
          </b-button>
        </div>
      </div>

      <Transition>
        <div class="py-3 px-5 outer" v-if="state.formData.status">
          <div class="row">
            <span>{{ $t('alert.monitoring-hours') }} <span class="text-danger">*</span></span>
            <div class="col-md-2 col-sm-4">
              <flat-pickr :disabled="!state.formData.status || disabled" :placeholder="'開始'" id="publicTime"
                v-model="state.formData.startTime" :config="state.dateTimeConfigStartTime" class="form-control" />
              <div v-if="v$.startTime.required.$invalid && invalid" class="error-text-show">
                {{ $t('msg.pleaseSelect', { field: '監視時間帯' }) }}
              </div>
            </div>
            <span style="padding: 0; margin-top: 10px; width: 10px">~</span>
            <div class="col-md-2 col-sm-4">
              <flat-pickr :disabled="!state.formData.status || disabled" :placeholder="'終了'" id="publicTime"
                v-model="state.formData.endTime" :config="state.dateTimeConfigEndTime" class="form-control" />
              <div v-if="v$.endTime.required.$invalid && invalid" class="error-text-show">
                {{ $t('msg.pleaseSelect', { field: '監視時間帯' }) }}
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-4 col-sm-8 d-flex">
              <div>
                <label for="person" class="label mt-3">
                  {{ $t('alert.numberOfGuest') }} <span class="text-danger">*</span>
                  <input @keydown="onKeydown($event)" v-model="state.formData.person" @change="changeNumberOfGuest"
                    :disabled="!state.formData.status || disabled" type="text" maxlength="255" id="person" placeholder="1"
                    class="form-control" />
                </label>
                <div v-if="v$.person.required.$invalid && invalid" class="error-text-show">
                  {{ $t('field_required', { field: $t('alert.numberOfGuest') }) }}
                </div>
              </div>

              <span class="pl-3" style="margin-top: 46px">{{ $t('alert.people') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-8 d-flex">
              <div>
                <label for="minutes" class="label mt-3">
                  {{ $t('alert.countStayTime') }} <span class="text-danger">*</span>
                  <input @keydown="onKeydown($event)" v-model="state.formData.minutes"
                    :disabled="!state.formData.status || disabled" type="text" maxlength="255" id="minutes"
                    placeholder="30" class="form-control" />
                  <div v-if="v$.minutes.required.$invalid && invalid" class="error-text-show">
                    {{ $t('field_required', { field: $t('alert.countStayTime') }) }}
                  </div>
                  <div v-else-if="v$.minutes.max.$invalid && invalid" class="error-text-show">
                    {{ $t('alert.maxTime') }}
                  </div>
                  <div v-else-if="v$.minutes.min.$invalid && invalid" class="error-text-show">
                    {{ $t('alert.minTime') }}
                  </div>
                </label>
              </div>

              <span class="pl-3" style="margin-top: 46px">{{ $t('alert.minute') }}</span>
            </div>
          </div>
        </div>
      </Transition>

    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Loading from "@/components/Loading.vue";
import { computed, onMounted, reactive, ref, watch } from "vue";
import i18n from "@/i18n";
import flatPickr from "vue-flatpickr-component";
import useVuelidate from "@vuelidate/core";
import { RULE_ALERT, ROLES } from "@/constants";
import store from "@/state/store";
import { pushNotificationService } from "@/services";
import { convertMinutesToTimes, getCountMinutes } from "@/helpers/moment";
import { padStart } from "lodash";

export default {
  name: "index",
  components: {
    Layout,
    PageHeader,
    Loading,
    flatPickr
  },
  setup() {
    const invalid = ref(false);
    const state = reactive({
      title: i18n.global.t('t-system-setting'),
      items: [
        {
          text: i18n.global.t('t-setting_'),
          active: true
        },
        {
          text: i18n.global.t('t-system-setting_'),
          active: true,
        },
      ],
      isLoading: false,
      dateTimeConfigStartTime: {
        enableTime: true,
        dateFormat: "H:i",
        minuteIncrement: 30,
        time_24hr: true,
        noCalendar: true,
        maxDate: "24:00"
      },
      dateTimeConfigEndTime: {
        enableTime: true,
        dateFormat: "H:i",
        minuteIncrement: 30,
        time_24hr: true,
        noCalendar: true,
        minDate: "00:00"
      },
      formData: {
        startTime: '',
        endTime: '',
        person: 1,
        minutes: '',
        status: false
      },
      notFound: false
    })

    const currentFacilityId = computed(() => store.getters['settings/getFacilityId']);
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || !currentUser.value);

    const form = computed(() => state.formData)
    const v$ = useVuelidate(RULE_ALERT, form);

    watch(() => currentFacilityId.value, () => {
      getAlertDetail();
    })

    const calculatorMinutes = (timeString, minutes, type = 'subtract') => {
      if (timeString === null) return
      const [hours, minutesStr] = timeString.split(':');

      const currentTime = new Date();
      currentTime.setHours(hours);
      currentTime.setMinutes(minutesStr);

      const newTime = type === 'subtract' ? new Date(currentTime - (minutes * 60000)) : new Date(currentTime.getTime() + (minutes * 60000));

      const newHours = padStart(newTime.getHours(), 2, '0');
      const newMinutes = padStart(newTime.getMinutes(), 2, '0');

      return `${newHours}:${newMinutes}`;
    }

    watch(() => state.formData.startTime, (startTime) => {
      if (startTime && typeof startTime === 'string') {
        state.dateTimeConfigEndTime.minDate = calculatorMinutes(startTime, 30, 'plus');
      }

    })

    watch(() => state.formData.endTime, (endTime) => {
      if (endTime && typeof endTime === 'string') {
        state.dateTimeConfigStartTime.maxDate = calculatorMinutes(endTime, 30)
      }
    })

    const handleSubmit = async () => {
      invalid.value = false;
      if (state.formData.status) {
        v$.value.$touch();

        invalid.value = v$.value.$invalid;
      } else {
        return;
      }

      if (!invalid.value) {
        state.isLoading = true
        try {
          const { startTime, endTime } = state.formData;

          const payload = {
            ...state.formData,
            startTime: getCountMinutes('00:00', startTime),
            endTime: getCountMinutes('00:00', endTime)
          }

          await pushNotificationService.updateAlertByFacility(currentFacilityId.value, payload)

          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'success',
            message: i18n.global.t('msg.saved')
          });
          state.notFound = false
        } catch (e) {
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: e.message
          });
          console.log(e, 'update')
        } finally {
          state.isLoading = false;
        }
      }
    }

    onMounted(() => {
      getAlertDetail()
    })

    const getAlertDetail = async () => {
      try {
        if (!currentFacilityId.value) return;
        const res = await pushNotificationService.getDetailAlertByFacility(currentFacilityId.value);
        if (res) {
          const { startTime, endTime, person, minutes, status } = res;
          state.formData = {
            ...state.formData,
            startTime: convertMinutesToTimes(startTime),
            endTime: convertMinutesToTimes(endTime),
            person: person,
            minutes: minutes,
            status: status !== false,
          }
          state.notFound = false
        } else {
          state.formData = {
            startTime: '',
            endTime: '',
            person: '',
            minutes: '',
            status: false
          }
          state.notFound = true
        }
      } catch (e) {
        state.formData = {
          startTime: '',
          endTime: '',
          person: '',
          minutes: '',
          status: false
        }
        state.notFound = true
        console.log(e, 'getAlertDetail error')
      }
    }

    const handleChangeInput = async () => {
      if (!state.notFound && currentFacilityId.value) {
        try {
          await pushNotificationService.updateAlertByFacility(currentFacilityId.value, { status: state.formData.status });
        } catch (e) {
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: e.message
          });
        }
      }
      console.log(state.formData.status, 'state.formData.status')
    }

    const onKeydown = (e) => {
      const regex = /[a-zA-Z]+/;
      const regex_special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
      const checkViet = /[\u00C0-\u1EF9]/g;

      if ((regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') || regex_special.test(e.key) || checkViet.test(e.key) || e.key === '`' || e.key === ' ' || e.key === '~') {
        e.preventDefault();
      }
    }

    const changeNumberOfGuest = () => {
      if (state.formData.person == 0) {
        state.formData.person = 1
      }
    }

    return {
      state,
      invalid,
      v$,
      currentFacilityId,
      handleSubmit,
      handleChangeInput,
      onKeydown,
      disabled,
      changeNumberOfGuest
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox {
  font-size: 20px;
}

.pl-3 {
  padding-left: 0.5rem;
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}

.card-header-radius {
  border-radius: 7px 7px 0 0;
  font-size: 16px;
  color: var(--vz-heading-color);
}

.border-bottom {
  transition: border-radius 3s;
  border-radius: 7px 7px 5px 5px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
